<template>
<div class="rc-container">
  
  <div v-if="!isLoading" class="rc-row">
    <div class="rc-box-col-15">
      <InspectionList :start="startSub" :isAdmin="true" />
    </div>
  </div>
  
  <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
  <SpacerRow />
  
</div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import MC from "@/domain/session/MC.js";
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import InspectionList from "@/views/portal/inspections/list/InspectionList.vue"
import SpacerRow from '@/components/row/SpacerRow.vue';

export default {
  name: "admin-company-inspections-row",
  components: {
    InspectionList,
    SpacerRow, LoadingRow,
  },
  props: {
  },
  data() {
    return {
      tab: null,
      startSub: false,
      
      isLoading: true,
      loadingMessage: "",
      MC: new MC(),
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                 ]),
    companyId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.message = null;
      this.loadingMessage = "";
      ConnectionUtils.loadCompany(this);
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    startCompany: function() {
      this.startSub = true;
    },
  }
}
</script>